.cats {
    &__grid {
        display: grid;
        max-width: 1316px;
        margin: 0 auto;
        grid-template-columns: repeat(auto-fill, minmax(100px ,225px));
        padding: 52px 15px 0;
        grid-column-gap: 33px;
        grid-row-gap: 33px;
        text-align: center;
        justify-content: center;
    }
    &__item {
        position: relative;
        width: 225px;
        height: 225px;
        transition: all 0.3s;
        &:hover {
            transform: scale(1.14);
            box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.24), 0px 9px 18px rgba(0, 0, 0, 0.18);
            .cats__favorite {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__favorite {
        background: url("../../assets/images/heart.svg") center no-repeat;
        width: 50px;
        height: 50px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: 28px;
        right: 28px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            img {
                opacity: 1;
                visibility: visible;
            }
        }
        img {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            bottom: 6px;
            right: 5px;
            transition: all 0.3s;
            cursor: pointer;
            z-index: 2;
        }

    }
    &__item--image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        &-loaded {
            opacity: 1;
        }
    }
    &__loading {
        margin: 0 auto;
        text-align: center;
    }
}

.favorite {
    opacity: 1;
    visibility: visible;
    img {
        opacity: 1;
        visibility: visible;
    }
}

.button {
    text-align: center;
    margin: 50px 0;
    padding: 15px 30px;
    background-color: #2196F3;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
    &:hover{
        background-color: #155de2;
    }
}

