.header {
    background: #2196F3;
    min-height: 64px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
    &__container {
        width: 1440px;
        margin: 0 auto;
        padding: 0 62px;
    }
    &__list {
        display: flex;
        align-items: center;
    }
    &__item {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.25px;
        transition: all 0.3s;
        a {
            transition: all 0.3s;
            padding: 23px 10px;
            display: inline-block;
        }
        &:hover {
            a {
                background: #308fe7;
            }

        }
    }
}
